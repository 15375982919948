
import G2ColsImgCopy from '../g-2-cols-img-copy';

export default {
  name: 'g-tab-copy-media',
  components: {
    G2ColsImgCopy,
  },
  props: {
    tabsName: {
      type: Array,
      default: () => [],
    },
    tabsContent: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  mounted() {
    this.tabsContent.forEach((tab, index) => {
      if (tab.selected_at_load) {
        this.selectedTab = index;
      }
    });
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    },
  },
};
