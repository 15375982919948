
export default {
  name: 'g-wave-container',
  components: {
    WaveDownVectorNoColor: () =>
      import('assets/svg/squiggle/wave-down-vector-no-color.svg'),
    WaveUp: () => import('assets/svg/squiggle/wave-up.svg'),
  },
  props: {
    // takes the variable name for a color in the color palette e.g. purple-10
    color: {
      type: String,
      default: null,
    },
    hideWaveDown: {
      type: Boolean,
      default: false,
    },
    showWaveUp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      return this.color ?? this.themeColor;
    },
  },
};
