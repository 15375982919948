
import GArrow from '../g-arrow';
import GButtonV2 from '../g-button-v2';

export default {
  name: 'g-state-list',
  components: { GArrow, GButtonV2 },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    visibleCardsMobile: {
      type: [Number, String],
      default: 9,
    },
    visibleCardsTablet: {
      type: [Number, String],
      default: 9,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    visibleCards() {
      if (this.showAll) {
        return this.cards;
      }
      if (this.$isDesktop) {
        return this.cards;
      }
      if (this.$isTablet && this.visibleCardsTablet) {
        return this.cards.slice(0, this.visibleCardsTablet);
      }
      if (this.$isMobile && this.visibleCardsMobile) {
        return this.cards.slice(0, this.visibleCardsMobile);
      }
      return this.cards;
    },
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
  },
};
