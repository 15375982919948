
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-workplace-logos',
  components: {
    GLazyImage,
  },
  props: {
    workplaces: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    colDefault: {
      type: Number,
      default: 3,
    },
    colAboveSm: {
      type: Number,
      default: 3,
    },
    colAboveMd: {
      type: Number,
      default: 6,
    },
    colAboveLg: {
      type: Number,
      default: 6,
    },
    logosAlignment: {
      type: String,
      default: 'center',
    },
    marginBottom: {
      type: String,
      default: '1.875rem',
    },
  },
};
