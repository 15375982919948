
import richtext from '~/utils/richtext';
import statsData from '../lib/storyblok-local-json/storyblok-stats.json';

export default {
  name: 'signup-form',
  components: {
    GSignupForm: () => import('./goose/g-signup-form'),
    GStars: () => import('./goose/g-stars'),
  },
  computed: {
    hasSubheading() {
      return this.finalModalData.subheading !== '';
    },
    capterraStars() {
      return statsData.capterra_score;
    },
    capterraReviews() {
      return statsData.capterra_total_reviews;
    },
    finalModalData() {
      const type = this.$store.state.showFreemium ? '_freemium' : '';
      const signupModalData = this.$store.state.modal.signupModalData;
      return {
        heading: richtext(signupModalData[`heading${type}`], true),
        subheading: richtext(signupModalData[`subheading${type}`], true),
        ctaText: signupModalData[`cta_text${type}`],
        formId: signupModalData[`form_id${type}`],
        showSocialButton: signupModalData[`show_social_button${type}`],
        ShowCapterraRating: signupModalData[`show_capterra_rating${type}`],
        hideComponent: signupModalData[`hideComponent${type}`],
        inline: signupModalData[`inline${type}`],
      };
    },
  },
  methods: {
    richtext,
  },
};
