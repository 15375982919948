
import GFooter from '../goose/g-footer';
import parseLinkHref from '~/utils/parseLinkHref';
import richtext from '~/utils/richtext';

export default {
  name: 'menu-footer',
  components: {
    GFooter,
  },
  data() {
    return {
      menuFooter: require(`../../lib/storyblok-local-json/menu-footer-${this.$store.state.region}.json`),
    };
  },
  computed: {
    footerProps() {
      return {
        logoHomeUrl:
          this.$store.state.region === 'us'
            ? '/'
            : `/${this.$store.state.region}`,
        contents: this.menuFooter.content.menu_parents_footer.map(content => {
          return {
            header: content.header,
            subheader: content.subheader.map(sub => {
              return {
                subheader_text: sub.subheader_text,
                subheader_link: parseLinkHref(sub.subheader_link),
                tag: sub.tag,
              };
            }),
          };
        }),
        region: this.$store.state.region,
        twitterUrl: this.socialLinks.twitter_url,
        youtubeUrl: this.socialLinks.youtube_url,
        facebookUrl: this.socialLinks.facebook_url,
        linkedinUrl: this.socialLinks.linkedin_url,
        instagramUrl: this.socialLinks.instagram_url,
        legalAndPrivacyLink: this.legalAndPrivacy,
        privacyCenterLink: this.privacyCenter,
        footerClass: this.$store.state.menu.footerClass,
        address: richtext(this.menuFooter.content.address, true),
      };
    },
    socialLinks() {
      return this.menuFooter.content.social_links[0];
    },
    legalAndPrivacy() {
      return parseLinkHref(this.menuFooter.content.legal_and_privacy_link);
    },
    privacyCenter() {
      if (!this.menuFooter.content.privacy_center_link) {
        return null;
      }
      return parseLinkHref(this.menuFooter.content.privacy_center_link);
    },
  },
};
