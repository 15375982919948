
import GButtonV2 from '../g-button-v2';
import parseLinkHref from '~/utils/parseLinkHref';
import { convertStoryblokCTAs } from '~/utils/storyblokHelpers';

export default {
  name: 'g-stats-3-cols',
  components: { GButtonV2 },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalTargetMappings: {
        signup: '#signupModal',
        consultation: '#bookADemo',
      },
    };
  },
  methods: {
    getModalTarget(buttonType) {
      if (typeof this.modalTargetMappings[buttonType] !== 'undefined') {
        return this.modalTargetMappings[buttonType];
      }
      return '';
    },
    buttonProps(card) {
      return convertStoryblokCTAs(
        card,
        '',
        card.ctas[0].analyticsId || 'g-stats-3-cols'
      )?.ctas?.[0];
    },
    parseLinkHref,
  },
};
