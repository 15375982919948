
export default {
  name: 'g-toggle-icon',
  props: {
    isClosed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    toggleClass() {
      return this.isClosed ? 'is-closed' : '';
    },
  },
};
