
import richtext from '~/utils/richtext';
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-stats-quote',
  components: {
    GLazyImage,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    renderedQuote() {
      if (typeof this.quote.content.quote !== 'undefined') {
        return richtext(this.quote.content.quote);
      }
      return '';
    },
    byline() {
      if (typeof this.quote.content.quote === 'undefined') {
        return '';
      }

      if (this.color) {
        return this.quote.content.company;
      }

      let byline = `${this.quote.content.author}`;
      byline +=
        this.quote.content.author_title !== ''
          ? `, ${this.quote.content.author_title}`
          : '';
      byline +=
        this.quote.content.company !== ''
          ? `, ${this.quote.content.company}`
          : '';
      return byline;
    },
  },
};
