
const maxStars = 5;
export const starColours = ['yellow', 'spark'];

export default {
  name: 'g-stars',
  components: {
    Star: () => import('assets/svg/icons/star.svg'),
  },
  props: {
    stars: {
      type: Number,
      required: true,
      validator: stars => stars >= 0 && stars <= maxStars,
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: Number,
      default: 12,
    },
    height: {
      type: Number,
      default: 11,
    },
    /**
     * takes the variable name for a color in the color palette e.g. purple-40
     * must have a color below it for the background color
     * purple-10 is not valid as there is no purple-0
     * purple-20 is valid as there is a purple-10
     */
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      maxStars,
    };
  },
  computed: {
    starGap() {
      const gapSize = {
        small: 2,
        medium: 4,
        large: 6,
      };
      return `gap-${gapSize[this.size]}-px`;
    },
    mainColor() {
      const themeColors = {
        white: 'peach-40',
        yellow: 'peach-40',
        peach: 'peach-40',
        azure: 'peach-40',
        pink: 'yellow-40',
        purple: 'yellow-40',
        enterprise: 'yellow-40',
      };
      return this.color ?? themeColors[this.theme];
    },
    backgroundColor() {
      const [colorType, colorNumber] = this.mainColor.split('-');
      return `${colorType}-${colorNumber - 10}`;
    },
  },
  methods: {
    calcWidth(index) {
      if (index <= this.stars) {
        return 100;
      }

      const remainder = this.stars - (index - 1);
      if (remainder > 0 && remainder < 1) {
        return Math.round(remainder * 100);
      }

      return 0;
    },
  },
};
