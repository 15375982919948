
export default {
  name: 'g-social-links',
  components: {
    facebookLogo: () => import('assets/svg/icons/logo-facebook.svg'),
    linkedinLogo: () => import('assets/svg/icons/logo-linkedin.svg'),
    instagramLogo: () => import('assets/svg/icons/logo-instagram.svg'),
    youtubeLogo: () => import('assets/svg/icons/logo-youtube.svg'),
    twitterLogo: () => import('assets/svg/icons/logo-twitter.svg'),
    tiktokLogo: () => import('assets/svg/icons/logo-tiktok.svg'),
  },
  props: {
    twitterUrl: {
      type: String,
      required: true,
    },
    youtubeUrl: {
      type: String,
      required: true,
    },
    facebookUrl: {
      type: String,
      required: true,
    },
    linkedinUrl: {
      type: String,
      required: true,
    },
    instagramUrl: {
      type: String,
      required: true,
    },
    tiktokUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      icons: [
        'facebook',
        'instagram',
        'twitter',
        'linkedin',
        'youtube',
        'tiktok',
      ],
    };
  },
  methods: {
    getUrl(siteName) {
      return this[`${siteName}Url`];
    },
  },
};
