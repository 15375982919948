
import GButtonV2 from '../g-button-v2';
import GLazyImage from '../g-lazy-image';
import slider from 'mixins/slider';

export default {
  name: 'g-trust-carousel-quote',
  components: {
    GLazyImage,
    GButtonV2,
    'g-lottie-animation': () => import('../g-lottie-animation'),
  },
  mixins: [slider],
  props: {
    quotes: {
      type: Array,
      required: true,
    },
    quoteFont: {
      type: String,
      default: 'text-20',
    },
    animationUrl: {
      type: String,
      default: null,
    },
    animationPlaceholder: {
      type: Object,
      default: null,
    },
    buttonText: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'peach-10',
    },
  },
  data() {
    return {
      timerInterval: 9000,
      autoplay: true,
      offset: 0,
      slidesDisplayed: 1,
    };
  },
  computed: {
    slideQuantityMoreThanOne() {
      return this.quotes.length > 1;
    },
  },
  created() {
    this.offset = 20;
    // no sliding movement when we have just one slide
    if (this.quotes.length === 1) {
      this.autoplay = false;
    }
  },
};
