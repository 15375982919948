
import smoothScrollPolyfill from 'mixins/smoothScrollPolyfill';

export default {
  name: 'g-scroll-to',
  mixins: [smoothScrollPolyfill],
  props: {
    targetId: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Array,
      validator: value => value.length === 2,
      default() {
        return [0, 0];
      },
    },
  },
  methods: {
    scrollPage(e) {
      e.preventDefault();
      if (typeof window === 'undefined') {
        return;
      }

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const target = document.getElementById(this.targetId);
      const headerOffset = this.$store.state.menu.headerOffset;
      if (target !== null) {
        const offset = this.isMobile ? this.offset[0] : this.offset[1];
        const rect = target.getBoundingClientRect();
        window.scrollTo({
          top: scrollTop + rect.top - offset - headerOffset,
          behavior: 'smooth',
        });
      }
    },
  },
};
