
import GResourceCard from './g-resource-card';

export default {
  name: 'g-resource-cards',
  components: {
    GResourceCard,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
};
