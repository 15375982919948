
import cmsComponent from 'mixins/cmsComponent';
import cmsHeaderDarkOverhang from '~/components/cms-header-dark-overhang';

export default {
  name: 'training-hub-header',
  components: {
    cmsHeaderDarkOverhang,
  },
  mixins: [cmsComponent],
  mounted() {
    if (!document.cookie.includes('seenConfetti=true')) {
      this.loadScript();
    }
  },
  methods: {
    loadScript() {
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute(
        'src',
        'https://cdn.jsdelivr.net/npm/canvas-confetti@1.2.0/dist/confetti.browser.min.js'
      );
      scriptTag.async = true;
      document.head.appendChild(scriptTag);
      scriptTag.onload = this.fireConfetti;
    },
    fireConfetti() {
      if (window.confetti) {
        window.confetti({
          particleCount: 70,
          spread: 60,
          origin: { y: 0.7 },
          gravity: 0.8,
          angle: 105,
        });
        setTimeout(() => {
          window.confetti({
            particleCount: 70,
            spread: 60,
            origin: { y: 0.7 },
            gravity: 0.8,
            angle: 75,
          });
        }, 250);
        document.cookie = 'seenConfetti=true';
      }
    },
  },
};
