
export default {
  name: 'g-step-by-step-2-col',
  props: {
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
      validator: steps => steps.length >= 2 && steps.length <= 6,
    },
    termsContent: {
      type: String,
      default: '',
    },
  },
};
