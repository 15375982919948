
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-resource-card',
  components: {
    GLazyImage,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
};
