
export default {
  name: 'g-section-dark-overhang',
  components: {
    WaveDownVectorNoColor: () =>
      import('assets/svg/squiggle/wave-down-vector-no-color.svg'),
  },
  props: {
    overhangHeightPercentage: {
      type: String,
      required: true,
    },
    addWaveDown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      containerHeight: 0,
    };
  },
  computed: {
    backgroundHeight() {
      const overhangHeight = Number(this.overhangHeightPercentage) || 0.2;
      return this.containerHeight - this.containerHeight * overhangHeight;
    },
  },
  mounted() {
    const bgEl = document.querySelector('.g-section-dark-overhang-background');
    if (bgEl) {
      this.containerHeight = bgEl.offsetHeight;
    }
  },
};
