
import GLazyImage from '../g-lazy-image';
import Intersect from '~/utils/intersect';
import GButtonV2 from '../g-button-v2';
import smoothScrollPolyfill from '~/utils/smoothScrollPolyfill';

export default {
  name: 'g-testimonial-with-faq',
  components: {
    WavesUp: () => import('assets/svg/squiggle/wave-up.svg'),
    WavesDown: () => import('assets/svg/squiggle/wave-down.svg'),
    GLazyImage,
    Intersect,
    GButtonV2,
  },
  mixin: [smoothScrollPolyfill],
  props: {
    image: {
      type: Object,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    subline: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    faq: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stickyPadding: false,
      showMore: false,
      buttonText: 'Show more',
    };
  },
  computed: {
    content() {
      // the content is set to 2000 characters before showing the show more button
      if (this.faq.length > 2000 && !this.$isDesktop && !this.showMore) {
        return `${this.faq.slice(0, 2000)}[...]`;
      }
      return this.faq;
    },
  },
  methods: {
    triggerStickyHeader(status) {
      this.stickyPadding = !(status === 'enter');
    },
    triggerContent() {
      this.showMore = !this.showMore;
      this.buttonText = this.showMore ? 'Read less' : 'Read more';
      this.$nextTick(() => {
        if (!this.showMore) {
          const offset = window.innerWidth < 380 ? 400 : 300;
          window.scrollTo({
            top:
              this.$refs['faqContent'].offsetTop +
              this.$refs['faqContent'].offsetHeight +
              offset,
            behavior: 'smooth',
          });
        }
      });
    },
  },
};
