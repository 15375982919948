
import GButtonV2 from './goose/g-button-v2';
export default {
  name: 'page-scaffolder',
  components: {
    GButtonV2,
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
    region: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      required: false,
      default: '',
    },
    fieldName: {
      type: String,
      default: 'content',
    },
    initialBlocks: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    displayedBlocks() {
      const blocks = this.story.content[this.fieldName || 'content'];
      if (this.showAll) {
        return blocks;
      }
      return blocks.slice(0, this.initialBlocks);
    },
  },
  created() {
    if (
      this.initialBlocks === 0 ||
      this.initialBlocks >=
        this.story.content[this.fieldName || 'content'].length
    ) {
      this.showAll = true;
    }
  },
  methods: {
    showAllBlocks() {
      this.showAll = true;
    },
  },
};
