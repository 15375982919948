
export const sizes = [
  '0',
  '20',
  '30',
  '40',
  '60',
  '80',
  '110',
  '140',
  '170',
  '200',
];

export default {
  name: 'g-spacer',
  props: {
    size: {
      type: String,
      required: true,
      validator: size => sizes.includes(size),
    },
    sizeMobile: {
      type: String,
      required: false,
      default: function () {
        return this.size;
      },
      validator: size => sizes.includes(size),
    },
  },
  computed: {
    sizeClass() {
      return this.sizeMobile === this.size
        ? `pt-${this.sizeMobile}`
        : `pt-${this.sizeMobile} pt-md-${this.size}`;
    },
  },
};
