
import GPreloader from '../g-preloader';
import GButtonV2 from '../g-button-v2';
import { mapState } from 'vuex';
import parseLinkHref from '~/utils/parseLinkHref';
import eHawkTalonInit from '~/utils/eHawkTalon';

export default {
  name: 'g-signup-form',
  components: {
    GoogleIcon: () => import('assets/svg/icons/icon-google.svg'),
    FacebookIcon: () => import('assets/svg/icons/icon-facebook.svg'),
    GButtonV2,
    GPreloader,
  },
  props: {
    formId: {
      type: String,
      required: true,
    },
    ctaText: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    leftAlign: {
      type: Boolean,
      default: false,
    },
    showSocialButton: {
      type: Boolean,
      default: false,
    },
    // just for display / testing purposes
    showErrors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: null,
        name: null,
        firstName: null,
        lastName: null,
        terms: false,
        isDeputyEmail: false,
        formId: this.formId,
        talon: null,
      },
    };
  },
  computed: {
    ...mapState({
      signupModalData: state => state.modal.signupModalData,
      ipCountry: state => state.ipCountry,
      formSubmitted: state => state.form.submitForm,
    }),
    textColor() {
      const themeColors = {
        white: 'grey-60',
        yellow: 'grey-60',
        peach: 'grey-60',
        azure: 'grey-10',
        pink: 'grey-10',
        purple: 'grey-10',
        enterprise: 'grey-10',
      };
      return themeColors[this.theme];
    },
    linkColor() {
      const themeColors = {
        white: 'purple-50',
        yellow: 'purple-50',
        peach: 'purple-50',
        azure: 'grey-10',
        pink: 'grey-10',
        purple: 'grey-10',
        enterprise: 'grey-10',
      };
      return themeColors[this.theme];
    },
    transparentBorder() {
      return ['azure', 'pink', 'purple', 'enterprise'].includes(this.theme);
    },
    socialButtonThemeClasses() {
      const background = `bg-${this.theme === 'white' ? 'grey-30' : 'grey-10'}`;
      const border = `border border-solid border-${
        ['yellow', 'peach'].includes(this.theme)
          ? `${this.theme}-30`
          : 'transparent'
      }`;
      return `${background} ${border}`;
    },
    socialButtonColor() {
      return `text-${
        ['purple', 'enterprise'].includes(this.theme)
          ? this.themeColor
          : 'azure-60'
      }`;
    },
  },
  mounted() {
    if (process.client) {
      this.$store.commit('setLoadAssets', 'facebook,linkedin,recaptcha');

      eHawkTalonInit(this.formId);
    }
  },
  methods: {
    // we check that the store is loaded
    // in Goose we can't access the store
    storeLoaded() {
      return this.$store !== undefined;
    },
    validateFields() {
      let isValid = true;

      // parse the name field as First/Last name
      if (this.form.name) {
        this.parseName();
      } else {
        this.$store?.commit('form/showError', 'name');
        isValid = false;
      }
      // email
      const regex =
        /^(([a-zA-Z0-9]+([a-zA-Z0-9.+_-]{1}[a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]{1}[a-zA-Z\-0-9]*\.)+[a-zA-Z]{2,}))$/;
      const emailIsValid = regex.test(this.form.email);
      if (!emailIsValid) {
        this.$store.commit('form/showError', 'email');
        isValid = false;
      }
      // checkbox
      if (!this.form.terms) {
        this.$store.commit('form/showError', 'terms');
        isValid = false;
      }

      return isValid;
    },
    isDeputyEmail() {
      return /(@deputy|@deputec)\.com$/.test(this.form.email);
    },
    getError(field) {
      // we reset the state on the submit button as per default
      // default value used for testing
      this.submitBtnText = this.ctaText;
      return this.storeLoaded()
        ? this.$store?.state?.form?.errors[field]?.text
        : 'error feedback';
    },
    // returns errors that would be shown in the last line of the form, just above the CTA
    getLastLineError() {
      const errorTypes = ['terms', 'recaptcha', 'api', 'generic'];
      for (const errorType of errorTypes) {
        if (this.isInvalid(errorType)) {
          return this.getError(errorType);
        }
      }
      return null;
    },
    isInvalid(value) {
      if (this.$store && this.formId === this.$store?.state?.form?.formId) {
        return this.$store?.state?.form?.errors[value]?.isInvalid;
      } else {
        // we return this props here only to display error in Goose
        return this.showErrors;
      }
    },
    hideError(field) {
      if (this.$store && this.formId === this.$store?.state?.form?.formId) {
        this.$store.commit('form/hideError', field);
      }
    },
    hideErrors() {
      if (this.$store && this.formId !== this.$store?.state?.form?.formId) {
        this.$store.commit('form/hideError', 'name');
        this.$store.commit('form/hideError', 'email');
        this.$store.commit('form/hideError', 'terms');
        this.$store.commit('form/hideError', 'generic');
      }
    },
    parseName() {
      const name = this.form.name.trim().split(' ');
      this.form.firstName = name[0];
      if (name.length > 1) {
        this.form.lastName = name[name.length - 1];
      } else {
        this.form.lastName = name[0];
      }
    },
    signupEvent(event = null, social = null) {
      if (this.storeLoaded()) {
        this.$store.commit(`analytics/${event}`, true);
        this.$store.commit('form/setFormId', this.formId);
        social ? this.$store.commit('form/setSocial', social) : null;
      }
    },
    onKeyUp() {
      if (!this.form.email || !this.form.name) {
        this.signupEvent('signupStarted');
      }
    },
    connectWithSocial(social) {
      this.signupEvent('signupAttempt', social);
    },
    submit() {
      // we will hide all errors from the store in case multiple forms can be triggered on the same page
      this.hideErrors();
      // we make sure the form ID is correctly set
      this.storeLoaded()
        ? this.$store.commit('form/setFormId', this.formId)
        : null;
      this.form.isDeputyEmail = this.isDeputyEmail();
      const formIsValid = this.validateFields();

      if (formIsValid && this.storeLoaded()) {
        // get the talon input
        this.form.talon = this.$refs[`talon-${this.formId}`].value;
        this.form.spz_experiment1 = this.$refs.spz_experiment1.value;
        this.form.spz_experiment2 = this.$refs.spz_experiment2.value;
        const form = { ...this.$store.state.form, ...this.form };
        this.$store.commit('form/saveForm', form);
        // we will trigger the g-preloader when the submit button is clicked and all previous conditions are met
        this.$store.commit('form/submitForm', true);
      }
    },
    regionalisedTermsAndPrivacy(field) {
      const url = this.signupModalData[`${field}`];
      if (this.ipCountry === 'us' || this.$store.state.showFreemium) {
        return parseLinkHref(url);
      } else {
        const regex = /\b(us|au|gb)\/\b/g;
        return url.cached_url.replace(regex, `/${this.ipCountry}/`);
      }
    },
  },
};
