
import MarketoForm from './marketo-form';
import { mapState } from 'vuex';

export default {
  name: 'modal-newsletter',
  components: { MarketoForm },
  computed: {
    ...mapState({
      modalContent: state => state.modal.modalContent,
    }),
  },
};
