
export default {
  name: 'g-video',
  props: {
    link: {
      type: String,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    poster: {
      type: String,
      default: '',
    },
  },
  computed: {
    isVideo() {
      return (
        !this.link.includes('/') &&
        !this.link.includes('.com') &&
        this.link.includes('.mp4')
      );
    },
    thumbnailUrl() {
      if (this.poster !== '') {
        return this.poster;
      }
      return false;
    },
  },
  mounted() {
    if (this.autoplay) {
      const video = this.$refs.video;
      video.oncanplay = () => {
        video.play();
      };
    }
  },
  methods: {
    play() {
      const video = this.$refs.video;
      // If no controls displayed on the video, we can start/pause the video by clicking on it
      // otherwise we won't trigger it as if the controls as shown on the video this is the default behavior
      if (video.paused === true && !video.getAttribute('controls')) {
        video.play();
      } else if (video.paused === false && !this.controls) {
        video.pause();
      }
    },
    mouseOver() {
      const video = this.$refs.video;
      // we check if we need to show the controls on the video
      if (
        this.controls &&
        video.paused === false &&
        !video.getAttribute('controls')
      ) {
        video.setAttribute('controls', true);
      }
      // we unmute the video if not done yet
      // we need to do this because if the video is on autoplay Google policy is to
      // block any video on autoplay which have sound in it.
      if (video.getAttribute('muted')) {
        video.muted = false;
      }
    },
  },
};
