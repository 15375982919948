
export default {
  name: 'g-wave-curl-repeat',
  components: {
    WaveCurlRepeat: () => import('assets/svg/squiggle/wave-curl-repeat.svg'),
  },
  props: {
    total: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    backgroundColor() {
      return 'theme-yellow';
    },
  },
};
