
const __default__ = {
  name: 'g-type-writer',
  props: {
    texts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      animationSpeed: 3,
      typeWriterHeaderQuantity: this.texts.length,
    };
  },
  computed: {
    speed() {
      return `${this.animationSpeed}s`;
    },
    totalAnimationTime() {
      const gap = this.texts.length * this.animationSpeed;
      return `${gap}s`;
    },
    slidingGap() {
      return `-${90 * this.typeWriterHeaderQuantity}px`;
    },
    bgColor() {
      return `rgb(var(--${this.themeColor}))`;
    },
    headingColor() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'azure-60'
        : 'yellow-40';
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "24de98ba": (_vm.totalAnimationTime),
  "583c495c": (_vm.typeWriterHeaderQuantity),
  "d9c84016": (_vm.slidingGap),
  "42cc3b11": (_vm.bgColor),
  "acb4740c": (_vm.speed)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__